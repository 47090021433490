<template>
  <div
    :class="$style.root"
    ref="element"
  >
    <div :class="$style.top">
      <input
        v-model="queryInput"
        :class="$style.input"
        :placeholder="$t('search.placeholder')"
        ref="inputElement"
        tabindex="0"
      >

      <CoreButtonsCloseComponent
        :class="$style.button"
        @click="$emit('closeSelf')"
      />

    </div>
    <SvgoLine :class="$style.line" />

    <SearchHitsComponent
      :queryString="query"
    />
  </div>
</template>

<script setup lang="ts">
import { refDebounced } from '@vueuse/core'

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  }
})

const inputElement = ref(null)
const queryInput = ref(null)
const query = refDebounced(queryInput, 50)

const nuxtApp = useNuxtApp()
nuxtApp.hook('i18n:beforeLocaleSwitch', () => {
  queryInput.value = null
  query.value = null
})

const element = ref(null)
const emit = defineEmits(['closeSelf'])
onClickOutside(element, (event) => emit('closeSelf'))

watch(() => props?.isOpen, (isOpen) => {
  if (isOpen) {
    setTimeout(() => {
      inputElement.value?.focus()
    }, 100)
  } else {
    queryInput.value = null
    query.value = null
  }
})

const route = useRoute()
const path = computed(() => route?.path)
watch(path, () => {
  emit('closeSelf')
  queryInput.value = null
  query.value = null
})

</script>

<style module lang='scss'>
.root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-index--search);

  max-height: calc(var(--100vh) - (var(--unit--vertical) * 2));
  width: calc(33.33vw - (var(--unit--default) * 2));
  overflow: scroll;

  background-color: var(--color--primary);
  color: var(--color--background);
  padding: var(--unit--vertical) var(--unit--default) var(--unit--vertical) var(--unit--default);

  display: flex;
  flex-direction: column;

  @media (max-width: $breakpoint-l) {
    width: calc(50vw - (var(--unit--default) * 2));
  }

  @media (max-width: $breakpoint-s) {
    width: calc(100vw - (var(--unit--default) * 2));
  }
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1ch;
}

.button {
  composes: reset-button font-helvetica font-size-small from global;

  width: .75rem;
  height: .75rem;
}

.input {
  composes: reset-input font-helvetica font-size-small from global;
  flex: 1;
}

.input::placeholder {
  color: var(--color--background);
}

.line {
  overflow: visible;
  height: var(--stroke--default);
  width: 100%;

  margin-top: .25rem;
}

.line * {
  stroke: currentColor;
  stroke-width: var(--stroke--default);
  stroke-linecap: round;
}

.linkToParent:empty {
  display: none;
}
</style>
