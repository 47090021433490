<template>
  <menu
    :class="$style.root"
    ref="element"
    tabindex="0"
    @keyup.esc="$emit('onCloseMenu')"
  >
    <div :class="$style.content">
      <NavigationMenuHeadingComponent
        @onToggleMenu="$emit('onToggleMenu')"
      />

      <ul
        :class="$style.items"
      >
        <li
          v-for="(item, index) in items"
          :key="item?.id"
          :class="$style.item"
        >
          <NavigationMenuItemComponent :item="item" />
        </li>
        <li>
          <button
            :class="$style.searchButton"
            @click="[$emit('onToggleSearch'), $emit('onCloseMenu')]"
          >
            {{ $t('search.button.label') }}
          </button>
        </li>
        <li>
          <NavigationLanguageSwitcherComponent />
        </li>
      </ul>
    </div>
  </menu>
</template>

<script setup>
  const props = defineProps({
    isOpen: {
      type: Boolean,
      default: false
    }
  })

  // 1
  const router = useRouter()
  const emit = defineEmits(['onCloseMenu'])

  router.beforeEach((to, from) => {
    emit('onCloseMenu')
  })

  const element = ref(null)
  onClickOutside(element, (event) => emit('onCloseMenu') )
  onMounted(() => {
    element.value.focus()
  })

  // 2
  const i18nActiveIso = computed(() => baseI18nGetActiveIso())

  const { data, error } = await useAsyncData(() =>
    $fetch('/api/strapi-rest', { query: { path: queryMenusMenu(i18nActiveIso.value) } }),
    { watch: [i18nActiveIso] }
  )

  // 3
  const page = computed(() => baseStrapiGetAttributes(data))
  const items = computed(() => page.value?.items?.data)

</script>

<style module>
.root {
  margin: 0;
  padding: 0;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: var(--z-index--menu);

  width: 100%;
  max-width: var(--container--width);
  overflow: scroll;

  background-color: var(--color--brown);
  color: var(--color--white);
}

.root:focus {
  outline: none;
}

.content {
  padding: var(--padding--container);
}

.items {
  composes: reset-list font-size-large from global;
}

.searchButton {
  composes: reset-button from global;
  margin-top: var(--lh);
}

.searchButton:hover {
  color: var(--color--magenta);
}
</style>
