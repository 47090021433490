<template>
  <i18nLink
    :to="routeObject"
    :class="$style.root"
    :key="hit?.slug"
  >
    <span v-html="localizedData?.collectiveName || localizedData?.name || localizedData?.title" />
  </i18nLink>
</template>

<script setup>
  const props = defineProps({
    hit: Object,
    uid: String
  })

  const hitData = computed(() => {
    return props?.hit?._formatted || props?.hit
  })

  const localizedData = hitData?.localizations?.find(i => i?.locale === baseI18nGetActiveIso() ) || hitData

  const routeObject = computed(() => {
    return baseRouteGetObject(
      strapiGetRouteReference(props?.uid)?.name,
      props?.hit?.slug || props?.hit?.id
    )
  })

  const label = localizedData?.collectiveName || localizedData?.name || localizedData?.title
</script>

<style module>
.root {
  composes: reset-link from global;
}

.root:hover {
  color: var(--color--magenta);
}

.root mark {
  color: var(--color--primary);
  background-color: var(--color--background);
}
</style>
