<template>
  <div
    :class="$style.root"
    ref="el"
    :style="`--layout-height: ${height}px`"
  >
    <Transition name="mask">
      <NavigationMenuComponent
        v-show="menuOpen"
        :isOpen="menuOpen"
        @onToggleMenu="menuOpen = !menuOpen"
        @onCloseMenu="menuOpen = false"
        @onToggleSearch="searchOpen = !searchOpen"
      />
    </Transition>

    <Transition name="mask">
      <SearchComponent
        v-show="searchOpen"
        :isOpen="searchOpen"
        @closeSelf="searchOpen = false"
      />
    </Transition>

    <NavigationSiteHeaderComponent
      @onToggleMenu="menuOpen = !menuOpen"
      @onToggleSearch="searchOpen = !searchOpen"
    />

    <SharedGtagConsentComponent v-once />

    <main :class="$style.main">
      <slot />
    </main>
    <SharedFooterComponent v-once />
    <CoreSvgBackgroundComponent v-once />
  </div>
</template>

<script setup>
  const menuOpen = ref(false)
const searchOpen = ref(false)

  const el = ref(null)
  const { height } = useElementSize(el)
</script>

<style module>
.root {
  composes: font-default from global;

  display: flex;
  flex-direction: column;

  min-height: var(--100vh);
}

.grid {
  margin-top: var(--unit--default);
}

.main {
  flex: 1;

  display: flex;
  flex-direction: column;
}
</style>
