<template>
  <div :class="$style.root">
    <ul
      v-if="multisearchResults"
      :class="$style.results"
    >
      <li
        v-for="(searchResult) in multisearchResultsSorted" :key="searchResult?.indexUid"
        :class="$style.hitGroup"
        :data-single-type="searchResult?.indexUid?.endsWith('-page') ? '' : null"
      >
        <SearchHitsGroupLabelComponent
          :indexUid="searchResult?.indexUid"
        />
        <ul :class="$style.group">
          <li
            v-for="(hit, index) in searchResult?.hits"
            :key="hit?._meilisearch_id"
          >
            <SearchHitsHitComponent
              :hit="hit"
              :uid="searchResult?.indexUid"
            />
          </li>
        </ul>
      </li>
    </ul>

    <div
      v-else-if="query && noResultsDebounced"
      :class="$style.noResults"
      v-html="$t('search.noResults', { query: query })"
    />
  </div>
</template>

<script setup lang="ts">
import { refDebounced } from '@vueuse/core'
import { orderBy } from 'lodash-es'

const props = defineProps({
  queryString: {
    type: String,
    default: false
  }
})

const query = computed(() => props.queryString)

const noResults = ref(false)
const noResultsDebounced = refDebounced(noResults, 350)

const multisearchResults = ref(null)
const multisearchResultsRaw = ref(null)

const multisearchResultsSorted = computed(() => {
  return orderBy(multisearchResults.value, [(i) => i?.indexUid?.endsWith('-page'), (i) => getSearchLabel(i?.indexUid), (i) => i?.hits[0]?.title], ['desc', 'asc', 'asc'])
})

const hasHits = (results: array) => {
  if (!results) { return false }
  return results.map((result: object) => result.estimatedTotalHits).reduce((a, b) => a + b, 0) > 0
}

const filterResults = (results: array) => {
  if (!results) { return null }
  return results.filter((result: object) => result.estimatedTotalHits !== 0)
}

watch(query, async () => {
  const { data } = await useFetch('/api/meilisearch', { params: { query: query.value, locale: baseI18nGetActiveIso() } })
  const results = data?.value?.results
  if (!query.value) {
    multisearchResults.value = null
    multisearchResultsRaw.value = null
  } else if (!hasHits(results) || query.value.length === 0) {
    multisearchResults.value = null
    noResults.value = true
  } else {
    multisearchResults.value = filterResults(results)
    multisearchResultsRaw.value = results
    noResults.value = false
  }
})

const nuxtApp = useNuxtApp()
nuxtApp.hook('i18n:beforeLocaleSwitch', () => {
  multisearchResults.value = null
  multisearchResultsRaw.value = null
})


</script>

<style module>

.root {
}

.results {
  composes: reset-list font-size-small font-helvetica from global;
  margin-top: var(--unit--vertical);
}

.noResults {
  composes: font-size-small font-helvetica from global;
  margin-top: var(--unit--vertical);
}

.noResults mark {
  color: var(--color--primary);
  background-color: var(--color--background);
}

.group {
  composes: reset-list from global;
}

.hitGroup:not(:first-child):not([data-single-type]) {
  margin-top: var(--unit--vertical);
}

</style>
