import qs from 'qs'

export const queryMenusMenu = (locale: QueryLocaleString) => {
  const query = qs.stringify(
    {
      locale: locale || 'en-US',
      filters: {
        slug: {
          $eq: 'menu'
        }
      },
      nested: true,
      populate: {
        ...queryMenusItems
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `menus?${query}`
}
