const nuxtApp = useNuxtApp()
const { $i18n } = nuxtApp

export const getSearchLabel = (indexUid: string) => {
  switch (indexUid) {
    case 'bulletin-board-entry':
      return $i18n.t('pages.bulletinBoard.label')
      break
    case 'calendar-entry':
      return $i18n.t('pages.calendar.label')
      break
    case 'community-entry':
      return $i18n.t('pages.community.label')
      break
    case 'project-entry':
      return $i18n.t('pages.project.label')
      break
  }
}
