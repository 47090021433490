<template>
  <div
    v-if="linkItem"
    :class="$style.root"
  >
    <i18nLink :to="routeObject" :class="$style.link">
      {{ linkItem?.title }}
    </i18nLink>
  </div>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

const linkItem = computed(() => getLinkItem(props.item))

const routeObject = baseRouteGetObject(
  strapiGetRouteReference(linkItem?.value?.uid)?.name,
  linkItem?.value?.slug
)
</script>

<style module>
.root {
  padding-left: 1ch;
  text-indent: -1ch;
}

.link {
  composes: reset-link from global;
}

.link:hover,
.link:global(.router-link-active) {
  color: var(--color--magenta);
}

</style>
