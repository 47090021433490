<template>
  <nav
    :class="$style.root"
    ref="element"
    :data-sticky="sticky ? '' : null"
    :data-show-menu-button="showMenuButton ? '' : null"
    :data-mounted="mounted ? '' : null"
  >
    <i18n-link
      :to="{ name: 'index' }"
      :class="$style.link"
    >
      {{ $t('siteTitle') }}
    </i18n-link>

    <button
      :class="$style.button"
      @click="$emit('onToggleMenu')"
    >
      {{ $t('site.header.menu') }}
    </button>

    <div
      :class="$style.links"
      v-if="items"
      ref="elementItems"
    >
      <NavigationSiteHeaderItemComponent
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        :class="$style.link"
      />

      <button
        :class="$style.searchButton"
        @click="$emit('onToggleSearch')"
      >
        {{ $t('search.button.label') }}
      </button>
    </div>

    <NavigationLanguageSwitcherComponent />
  </nav>
</template>

<script setup>
const element = ref(null)
const elementItems = ref(null)
const mounted = ref(false)

const sticky = ref(false)
const showMenuButton = ref(false)
const { y } = useWindowScroll()
const { width } = useWindowSize()

// 1
const i18nActiveIso = computed(() => baseI18nGetActiveIso())

const { data, error } = await useAsyncData(() =>
  $fetch('/api/strapi-rest', { query: { path: queryMenusMenu(i18nActiveIso.value) } }),
  { watch: [i18nActiveIso] }
)

const page = computed(() => baseStrapiGetAttributes(data))
const items = computed(() => page.value?.items?.data)

// 2
const elementItemsHasOverflow = () => {
  return elementItems.value?.scrollWidth > elementItems.value?.offsetWidth
}

const prevYValue = ref(0)
const yDebounced = refDebounced(y, 25)
watch(yDebounced, (value) => {
  const dirUp = prevYValue.value > value
  sticky.value = dirUp && value > 0

  showMenuButton.value = elementItemsHasOverflow()
  prevYValue.value = value
})

watch(width, (value) => {
  showMenuButton.value = elementItemsHasOverflow()
})

onMounted(() => {
  showMenuButton.value = elementItemsHasOverflow()
  mounted.value = true
})

</script>

<style module>
.root {
  composes: font-size-default from global;

  display: flex;
  flex-direction: row;
  gap: calc(var(--unit--vertical) * 1);

  position: relative;

  z-index: var(--z-index--site-header);

  padding: var(--padding--container);
}

.root:not([data-mounted]) .button,
.root:not([data-mounted]) .links {
  visibility: hidden;
  pointer-events: none;
}

.root[data-sticky] {
  background-color: var(--color--background);
  position: sticky;
  top: 0;
}

html[data-route-name='bulletin-board'] .root[data-sticky] {
    background-color: var(--color--bulletin-board);
}

html[data-route-name='calendar'] .root[data-sticky] {
    background-color: var(--color--calendar);
}

html[data-route-name='community'] .root[data-sticky] {
    background-color: var(--color--community);
}

html[data-route-name*='organisation'] .root[data-sticky] {
    background-color: var(--color--organisation);
}

html[data-route-name='projects'] .root[data-sticky] {
    background-color: var(--color--projects);
}

.button {
  composes: reset-button from global;

  display: none;
}

.root[data-show-menu-button] .button {
  display: block;
}

.button:hover {
  color: var(--color--magenta);
}

.root[data-show-menu-button] .links {
  visibility: hidden;
  pointer-events: none;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  margin-left: auto;
}

.link {
  composes: reset-link link from global;
  white-space: nowrap;
}

.link:not(:first-of-type) {
  margin-left: calc(var(--unit--vertical) * 1);
}

.searchButton {
  composes: reset-button from global;
  margin-left: calc(var(--unit--vertical) * 1);
}

.searchButton:hover {
  color: var(--color--magenta);
}
</style>
