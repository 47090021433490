<template>
  <div :class="$style.root">
    <h1 :class="$style.title">
      <I18nLink
        :to="{name: 'index'}"
        :class="$style.link"
        @click="$emit('onToggleMenu')"
      >
        {{ $t('siteTitle') }}
      </I18nLink>
    </h1>
    <div>
      <CoreButtonsCloseComponent :class="$style.svg" @click="$emit('onToggleMenu')" />
    </div>
  </div>
</template>

<style module>
.root {
  display: flex;
  gap: 1ch;
  align-items: baseline;

  justify-content: space-between;

  margin-bottom: var(--unit--default);
}

.title {
  composes: reset-heading font-size-default from global;
}

.link {
  composes: reset-link link from global;
}
</style>
