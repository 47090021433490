<template>
  <ul :class="$style.root">
    <li
      v-for="locale in locales"
      :key="locale.code"
      :class="$style.locale"
    >
      <NuxtLink
      :key="locale.code"
      :to="switchLocalePath(locale.code)"
      :class="$style.link"
      :data-current=" currentLocale === locale.code ? '' : null "
      >
        {{ locale.code }}
      </NuxtLink>
    </li>
  </ul>
</template>

<script setup>
const { locale: currentLocale, locales } = useI18n()
const switchLocalePath = useSwitchLocalePath()
</script>

<style module>
.root {
  composes: reset-list from global;

  display: flex;

  white-space: nowrap;
}

.link {
  composes: reset-link from global;

  display: inline-block;
}

.locale:not(:last-of-type):after {
  content: '/';
}

.link:hover,
.link[data-current] {
  color: var(--color--magenta);
}

.link:not([href]) {
  color: var(--color--gray);
}
</style>
